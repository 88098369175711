// extracted by mini-css-extract-plugin
export var button = "campsiteFilter-module--button--188d1 text-sm md_text-base py-ms-2 px-ms1";
export var campsiteContainer = "campsiteFilter-module--campsiteContainer--860c1 grid grid-cols-1 gap-ms4";
export var checkbox = "campsiteFilter-module--checkbox--4060c rounded-full h-6 w-6 accent-primary transition duration-200 ease-in-out";
export var checkboxContainer = "campsiteFilter-module--checkboxContainer--5ab63";
export var checkboxWrapper = "campsiteFilter-module--checkboxWrapper--9add0 flex items-center gap-ms-2 mt-ms1";
export var clearButton = "campsiteFilter-module--clearButton--1c6e7 block mt-ms2 underline underline-offset-4";
export var closeButton = "campsiteFilter-module--closeButton--0f5c2 absolute -left-ms5 top-ms2 -rotate-90 w-ms2 h-ms2 inline-block align-middle fill-white pointer-events-none";
export var container = "campsiteFilter-module--container--89742 w-full";
export var filterButton = "campsiteFilter-module--filterButton--c218b w-full h-full text-white py-ms2";
export var filtersWrapper = "campsiteFilter-module--filtersWrapper--189f7 overflow-y-auto mb-ms7";
export var fixed = "campsiteFilter-module--fixed--e4ee6 fixed bottom-0 left-0 bg-primary w-full";
export var labelWrapper = "campsiteFilter-module--labelWrapper--ef185 block mt-ms1";
export var select = "campsiteFilter-module--select--68e55 ml-ms-2 rounded border focus_outline-none";
export var topbar = "campsiteFilter-module--topbar--a8cc3 flex justify-between items-center mb-ms1 text-sm md_text-base";
export var wrapper = "campsiteFilter-module--wrapper--9911e container mx-auto";