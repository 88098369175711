import { graphql } from 'gatsby'
import React from 'react'
import { Seo } from '../../components/seo'
import PageTitle from '../../components/pageTitle'
import { Spacer } from '../../components/spacer'
import CampsiteFilter from '../../components/campsiteFilter'

const CampsitesPage = ({ data: { markdownRemark } }) => {
    const { frontmatter } = markdownRemark

    return (
        <>
            <PageTitle
                title={frontmatter?.PageTitle?.Title}
                text={frontmatter?.PageTitle?.Text}
            />
            <Spacer />
            <CampsiteFilter campsites={frontmatter?.campsites} />
        </>
    )
}

export default CampsitesPage

export const Head = ({ location, data: { markdownRemark } }) => {
    const { frontmatter } = markdownRemark
    return (
        <Seo
            title={frontmatter?.metaTitle}
            description={frontmatter?.metaDesc}
            pathname={location?.pathname}
        ></Seo>
    )
}

export const pageQuery = graphql`
    {
        markdownRemark(frontmatter: { id: { eq: "campsites" } }) {
            frontmatter {
                metaTitle
                metaDesc
                PageTitle {
                    Title
                    Text
                }
                campsites {
                    id
                    title
                    caption
                    sustainability
                    description
                    link
                    image {
                        childImageSharp {
                            gatsbyImageData(width: 1280)
                        }
                    }
                    attributes {
                        nearestTown
                        local
                        sustainabilityScore
                        campfire
                        pets
                        priority
                        perfectFor {
                            couples
                            families
                        }
                        activities {
                            hiking
                            swimming
                            beaches
                            surfing
                            historical
                            sailing
                            kayaking
                            stars
                            fishing
                            cycling
                        }
                        vibe {
                            lively
                            quiet
                            relaxing
                            rustic
                            familyFun
                        }
                    }
                }
            }
        }
    }
`
