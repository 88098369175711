// extracted by mini-css-extract-plugin
export var attributes = "campsite-module--attributes--d703f my-ms2";
export var button = "campsite-module--button--cb520 mt-ms2";
export var caption = "campsite-module--caption--fe2ad my-ms1";
export var container = "campsite-module--container--5d103 animate-fade-in-up bg-light grid grid-cols-1 lg_grid-cols-2";
export var deets = "campsite-module--deets--caaf6 flex gap-ms2 mt-ms1";
export var detailsWrapper = "campsite-module--detailsWrapper--fd421 relative px-ms2 pt-ms1 pb-ms2 md_px-ms4 md_pt-ms3 md_pb-ms4 text-stone-500 font-light";
export var gatsbyImage = "campsite-module--gatsbyImage--8719d group-hover_scale-105 transition";
export var image = "campsite-module--image--0250b relative overflow-hidden group";
export var like = "campsite-module--like--4224e w-ms2 h-ms2 inline-block align-middle";
export var location = "campsite-module--location--6cfa0 grid grid-cols-1 md_grid-cols-2 gap-ms2 mt-ms1";
export var strong = "campsite-module--strong--b931b block my-ms-2";
export var sustainability = "campsite-module--sustainability--0d4ea mt-ms1";
export var title = "campsite-module--title--7f158 text-stone-700";
export var titleWrapper = "campsite-module--titleWrapper--606d5 flex justify-between items-center";