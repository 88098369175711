import React, { useState, Fragment } from 'react'
import { useStyle } from '../../hooks/classify'
import { Dialog, Transition } from '@headlessui/react'
import * as defaultClasses from './campsiteFilter.module.css'
import Campsite from '../campsite'
import DialogModule from '../dialogModel/DialogModel'
import CloseIcon from '../../images/icons/Close'

const CampsiteFilter = ({ campsites, parentClasses }) => {
    const classes = useStyle(defaultClasses, parentClasses)

    const [isOpen, setIsOpen] = useState(false)

    const [filters, setFilters] = useState({
        perfectFor: [],
        activities: [],
        vibe: [],
        local: '',
        nearestTown: '',
        sustainabilityScore: null,
        campfire: 'All',
        pets: 'All',
        sortOrder: 'priority',
    })

    const localOptions = ['North Coast', 'Central', 'South Coast']

    const nearestTownOptions = ['Fowey', 'Padstow', 'Newquay', 'St Ives']

    const handleFilterChange = (filterType, selectedValue) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [filterType]: selectedValue,
        }))
    }

    // const handleSortOrderChange = () => {
    //     setFilters((prevFilters) => ({
    //         ...prevFilters,
    //         sortOrder:
    //             prevFilters.sortOrder === 'sustainability'
    //                 ? 'priority'
    //                 : 'sustainability',
    //     }))
    // }

    const handleClearAll = () => {
        setFilters({
            perfectFor: [],
            activities: [],
            vibe: [],
            local: '',
            nearestTown: '',
            sustainabilityScore: null,
            campfire: 'All',
            pets: 'All',
            sortOrder: 'priority',
        })
    }

    const filteredCampsites = campsites.filter((campsite) => {
        const {
            perfectFor,
            activities,
            vibe,
            local,
            nearestTown,
            sustainabilityScore,
            campfire,
            pets,
        } = filters

        const isCheckboxSelected = (checkbox, selectedValues) =>
            Object.entries(checkbox).some(
                ([key, value]) => selectedValues.includes(key) && value
            )

        return (
            (perfectFor.length === 0 ||
                isCheckboxSelected(
                    campsite.attributes.perfectFor,
                    perfectFor
                )) &&
            (activities.length === 0 ||
                isCheckboxSelected(
                    campsite.attributes.activities,
                    activities
                )) &&
            (vibe.length === 0 ||
                isCheckboxSelected(campsite.attributes.vibe, vibe)) &&
            (local === '' || local === campsite.attributes.local) &&
            (nearestTown === '' ||
                nearestTown === campsite.attributes.nearestTown) && // Updated condition
            (sustainabilityScore === null ||
                sustainabilityScore ===
                    campsite.attributes.sustainabilityScore) &&
            (campfire === 'All' ||
                (campfire === 'true' && campsite.attributes.campfire) ||
                (campfire === 'false' && !campsite.attributes.campfire)) && // Updated condition
            (pets === 'All' ||
                (pets === 'true' && campsite.attributes.pets) ||
                (pets === 'false' && !campsite.attributes.pets)) // Updated condition
        )
    })

    const sortingKeys = {
        priority: 'priority',
        sustainability: 'sustainabilityScore',
    }

    const sortedCampsites = filteredCampsites.sort((a, b) => {
        const sortKey = sortingKeys[filters.sortOrder]
        const valueA = a.attributes[sortKey]
        const valueB = b.attributes[sortKey]

        if (valueA < valueB) return -1
        if (valueA > valueB) return 1
        return 0
    })

    const total =
        filteredCampsites.length +
        ' ' +
        (filteredCampsites.length === 1 ? 'Campsite' : 'Campsites')

    return (
        <div className={classes.container}>
            <div className={classes.wrapper}>
                <div>
                    <div className={classes.topbar}>
                        <button
                            className={'btnSecondary ' + classes.button}
                            onClick={handleClearAll}
                            title="Clear filters"
                        >
                            Clear Filters
                        </button>
                        {total}
                        <button
                            className={'btnPrimary ' + classes.button}
                            onClick={() => setIsOpen(true)}
                            title="Filter campsites"
                        >
                            Filter Campsites
                        </button>
                    </div>
                    <DialogModule isOpen={isOpen} setIsOpen={setIsOpen}>
                        <div className="flex min-h-full relative">
                            <Transition.Child
                                as={Fragment}
                                className="flex flex-col bg-white fixed top-0 right-0 p-ms3 w-[300px] h-full transition duration-700"
                                enter="ease-in-out"
                                enterFrom="translate-x-full opacity-0"
                                enterTo="translate-x-0 opacity-100"
                                leave="ease-out"
                                leaveFrom="translate-x-0 opacity-100"
                                leaveTo="translate-x-full opacity-0"
                            >
                                <Dialog.Panel>
                                    <CloseIcon
                                        className={classes.closeButton}
                                    />

                                    <div className={classes.filtersWrapper}>
                                        <div
                                            className={
                                                classes.dropdownContainer
                                            }
                                        >
                                            <label
                                                className={classes.labelWrapper}
                                            >
                                                Location:
                                                <select
                                                    className={classes.select}
                                                    value={filters.local}
                                                    onChange={(e) =>
                                                        handleFilterChange(
                                                            'local',
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <option value="">
                                                        All
                                                    </option>
                                                    {localOptions.map((loc) => (
                                                        <option
                                                            key={loc}
                                                            value={loc}
                                                        >
                                                            {loc}
                                                        </option>
                                                    ))}
                                                </select>
                                            </label>
                                            or <br />
                                            <label>
                                                Nearest Town:
                                                <select
                                                    className={classes.select}
                                                    value={filters.nearestTown}
                                                    onChange={(e) =>
                                                        handleFilterChange(
                                                            'nearestTown',
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <option value="">
                                                        All
                                                    </option>
                                                    {nearestTownOptions.map(
                                                        (town) => (
                                                            <option
                                                                key={town}
                                                                value={town}
                                                            >
                                                                {town}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </label>
                                            <label
                                                className={classes.labelWrapper}
                                            >
                                                Campfire:
                                                <select
                                                    className={classes.select}
                                                    value={filters.campfire}
                                                    onChange={(e) =>
                                                        handleFilterChange(
                                                            'campfire',
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <option value="All">
                                                        All
                                                    </option>
                                                    <option value="true">
                                                        Yes
                                                    </option>
                                                    <option value="false">
                                                        No
                                                    </option>
                                                </select>
                                            </label>
                                            <label
                                                className={classes.labelWrapper}
                                            >
                                                Pets:
                                                <select
                                                    className={classes.select}
                                                    value={filters.pets}
                                                    onChange={(e) =>
                                                        handleFilterChange(
                                                            'pets',
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <option value="All">
                                                        All
                                                    </option>
                                                    <option value="true">
                                                        Yes
                                                    </option>
                                                    <option value="false">
                                                        No
                                                    </option>
                                                </select>
                                            </label>
                                        </div>
                                        <div
                                            className={
                                                classes.checkboxContainer
                                            }
                                        >
                                            <label
                                                className={classes.labelWrapper}
                                            >
                                                Perfect For:
                                                <div
                                                    className={
                                                        classes.checkboxWrapper
                                                    }
                                                >
                                                    <input
                                                        className={
                                                            classes.checkbox
                                                        }
                                                        type="checkbox"
                                                        value="couples"
                                                        checked={filters.perfectFor.includes(
                                                            'couples'
                                                        )}
                                                        onChange={(e) => {
                                                            const value =
                                                                e.target.value
                                                            setFilters(
                                                                (
                                                                    prevFilters
                                                                ) => ({
                                                                    ...prevFilters,
                                                                    perfectFor:
                                                                        prevFilters.perfectFor.includes(
                                                                            value
                                                                        )
                                                                            ? prevFilters.perfectFor.filter(
                                                                                  (
                                                                                      item
                                                                                  ) =>
                                                                                      item !==
                                                                                      value
                                                                              )
                                                                            : [
                                                                                  ...prevFilters.perfectFor,
                                                                                  value,
                                                                              ],
                                                                })
                                                            )
                                                        }}
                                                    />
                                                    <label htmlFor="couples">
                                                        Couples
                                                    </label>
                                                </div>
                                                <div
                                                    className={
                                                        classes.checkboxWrapper
                                                    }
                                                >
                                                    <input
                                                        className={
                                                            classes.checkbox
                                                        }
                                                        type="checkbox"
                                                        value="families"
                                                        checked={filters.perfectFor.includes(
                                                            'families'
                                                        )}
                                                        onChange={(e) => {
                                                            const value =
                                                                e.target.value
                                                            setFilters(
                                                                (
                                                                    prevFilters
                                                                ) => ({
                                                                    ...prevFilters,
                                                                    perfectFor:
                                                                        prevFilters.perfectFor.includes(
                                                                            value
                                                                        )
                                                                            ? prevFilters.perfectFor.filter(
                                                                                  (
                                                                                      item
                                                                                  ) =>
                                                                                      item !==
                                                                                      value
                                                                              )
                                                                            : [
                                                                                  ...prevFilters.perfectFor,
                                                                                  value,
                                                                              ],
                                                                })
                                                            )
                                                        }}
                                                    />
                                                    <label htmlFor="families">
                                                        Families
                                                    </label>
                                                </div>
                                            </label>
                                            <label
                                                className={classes.labelWrapper}
                                            >
                                                Activities:
                                                <div
                                                    className={
                                                        classes.checkboxWrapper
                                                    }
                                                >
                                                    <input
                                                        className={
                                                            classes.checkbox
                                                        }
                                                        type="checkbox"
                                                        value="hiking"
                                                        checked={filters.activities.includes(
                                                            'hiking'
                                                        )}
                                                        onChange={(e) => {
                                                            const value =
                                                                e.target.value
                                                            setFilters(
                                                                (
                                                                    prevFilters
                                                                ) => ({
                                                                    ...prevFilters,
                                                                    activities:
                                                                        prevFilters.activities.includes(
                                                                            value
                                                                        )
                                                                            ? prevFilters.activities.filter(
                                                                                  (
                                                                                      item
                                                                                  ) =>
                                                                                      item !==
                                                                                      value
                                                                              )
                                                                            : [
                                                                                  ...prevFilters.activities,
                                                                                  value,
                                                                              ],
                                                                })
                                                            )
                                                        }}
                                                    />
                                                    <label htmlFor="hiking">
                                                        Hiking
                                                    </label>
                                                </div>
                                                <div
                                                    className={
                                                        classes.checkboxWrapper
                                                    }
                                                >
                                                    <input
                                                        className={
                                                            classes.checkbox
                                                        }
                                                        type="checkbox"
                                                        value="swimming"
                                                        checked={filters.activities.includes(
                                                            'swimming'
                                                        )}
                                                        onChange={(e) => {
                                                            const value =
                                                                e.target.value
                                                            setFilters(
                                                                (
                                                                    prevFilters
                                                                ) => ({
                                                                    ...prevFilters,
                                                                    activities:
                                                                        prevFilters.activities.includes(
                                                                            value
                                                                        )
                                                                            ? prevFilters.activities.filter(
                                                                                  (
                                                                                      item
                                                                                  ) =>
                                                                                      item !==
                                                                                      value
                                                                              )
                                                                            : [
                                                                                  ...prevFilters.activities,
                                                                                  value,
                                                                              ],
                                                                })
                                                            )
                                                        }}
                                                    />
                                                    <label htmlFor="swimming">
                                                        Swimming
                                                    </label>
                                                </div>
                                                <div
                                                    className={
                                                        classes.checkboxWrapper
                                                    }
                                                >
                                                    <input
                                                        className={
                                                            classes.checkbox
                                                        }
                                                        type="checkbox"
                                                        value="beaches"
                                                        checked={filters.activities.includes(
                                                            'beaches'
                                                        )}
                                                        onChange={(e) => {
                                                            const value =
                                                                e.target.value
                                                            setFilters(
                                                                (
                                                                    prevFilters
                                                                ) => ({
                                                                    ...prevFilters,
                                                                    activities:
                                                                        prevFilters.activities.includes(
                                                                            value
                                                                        )
                                                                            ? prevFilters.activities.filter(
                                                                                  (
                                                                                      item
                                                                                  ) =>
                                                                                      item !==
                                                                                      value
                                                                              )
                                                                            : [
                                                                                  ...prevFilters.activities,
                                                                                  value,
                                                                              ],
                                                                })
                                                            )
                                                        }}
                                                    />
                                                    <label htmlFor="beaches">
                                                        Beaches
                                                    </label>
                                                </div>
                                                <div
                                                    className={
                                                        classes.checkboxWrapper
                                                    }
                                                >
                                                    <input
                                                        className={
                                                            classes.checkbox
                                                        }
                                                        type="checkbox"
                                                        value="surfing"
                                                        checked={filters.activities.includes(
                                                            'surfing'
                                                        )}
                                                        onChange={(e) => {
                                                            const value =
                                                                e.target.value
                                                            setFilters(
                                                                (
                                                                    prevFilters
                                                                ) => ({
                                                                    ...prevFilters,
                                                                    activities:
                                                                        prevFilters.activities.includes(
                                                                            value
                                                                        )
                                                                            ? prevFilters.activities.filter(
                                                                                  (
                                                                                      item
                                                                                  ) =>
                                                                                      item !==
                                                                                      value
                                                                              )
                                                                            : [
                                                                                  ...prevFilters.activities,
                                                                                  value,
                                                                              ],
                                                                })
                                                            )
                                                        }}
                                                    />
                                                    <label htmlFor="surfing">
                                                        Surfing
                                                    </label>
                                                </div>
                                                <div
                                                    className={
                                                        classes.checkboxWrapper
                                                    }
                                                >
                                                    <input
                                                        className={
                                                            classes.checkbox
                                                        }
                                                        type="checkbox"
                                                        value="historical"
                                                        checked={filters.activities.includes(
                                                            'historical'
                                                        )}
                                                        onChange={(e) => {
                                                            const value =
                                                                e.target.value
                                                            setFilters(
                                                                (
                                                                    prevFilters
                                                                ) => ({
                                                                    ...prevFilters,
                                                                    activities:
                                                                        prevFilters.activities.includes(
                                                                            value
                                                                        )
                                                                            ? prevFilters.activities.filter(
                                                                                  (
                                                                                      item
                                                                                  ) =>
                                                                                      item !==
                                                                                      value
                                                                              )
                                                                            : [
                                                                                  ...prevFilters.activities,
                                                                                  value,
                                                                              ],
                                                                })
                                                            )
                                                        }}
                                                    />
                                                    <label htmlFor="historical">
                                                        Historical
                                                    </label>
                                                </div>
                                                <div
                                                    className={
                                                        classes.checkboxWrapper
                                                    }
                                                >
                                                    <input
                                                        className={
                                                            classes.checkbox
                                                        }
                                                        type="checkbox"
                                                        value="sailing"
                                                        checked={filters.activities.includes(
                                                            'sailing'
                                                        )}
                                                        onChange={(e) => {
                                                            const value =
                                                                e.target.value
                                                            setFilters(
                                                                (
                                                                    prevFilters
                                                                ) => ({
                                                                    ...prevFilters,
                                                                    activities:
                                                                        prevFilters.activities.includes(
                                                                            value
                                                                        )
                                                                            ? prevFilters.activities.filter(
                                                                                  (
                                                                                      item
                                                                                  ) =>
                                                                                      item !==
                                                                                      value
                                                                              )
                                                                            : [
                                                                                  ...prevFilters.activities,
                                                                                  value,
                                                                              ],
                                                                })
                                                            )
                                                        }}
                                                    />
                                                    <label htmlFor="sailing">
                                                        Sailing
                                                    </label>
                                                </div>
                                                <div
                                                    className={
                                                        classes.checkboxWrapper
                                                    }
                                                >
                                                    <input
                                                        className={
                                                            classes.checkbox
                                                        }
                                                        type="checkbox"
                                                        value="kayaking"
                                                        checked={filters.activities.includes(
                                                            'kayaking'
                                                        )}
                                                        onChange={(e) => {
                                                            const value =
                                                                e.target.value
                                                            setFilters(
                                                                (
                                                                    prevFilters
                                                                ) => ({
                                                                    ...prevFilters,
                                                                    activities:
                                                                        prevFilters.activities.includes(
                                                                            value
                                                                        )
                                                                            ? prevFilters.activities.filter(
                                                                                  (
                                                                                      item
                                                                                  ) =>
                                                                                      item !==
                                                                                      value
                                                                              )
                                                                            : [
                                                                                  ...prevFilters.activities,
                                                                                  value,
                                                                              ],
                                                                })
                                                            )
                                                        }}
                                                    />
                                                    <label htmlFor="kayaking">
                                                        Kayaking
                                                    </label>
                                                </div>
                                                <div
                                                    className={
                                                        classes.checkboxWrapper
                                                    }
                                                >
                                                    <input
                                                        className={
                                                            classes.checkbox
                                                        }
                                                        type="checkbox"
                                                        value="stars"
                                                        checked={filters.activities.includes(
                                                            'stars'
                                                        )}
                                                        onChange={(e) => {
                                                            const value =
                                                                e.target.value
                                                            setFilters(
                                                                (
                                                                    prevFilters
                                                                ) => ({
                                                                    ...prevFilters,
                                                                    activities:
                                                                        prevFilters.activities.includes(
                                                                            value
                                                                        )
                                                                            ? prevFilters.activities.filter(
                                                                                  (
                                                                                      item
                                                                                  ) =>
                                                                                      item !==
                                                                                      value
                                                                              )
                                                                            : [
                                                                                  ...prevFilters.activities,
                                                                                  value,
                                                                              ],
                                                                })
                                                            )
                                                        }}
                                                    />
                                                    <label htmlFor="stars">
                                                        Stars
                                                    </label>
                                                </div>
                                                <div
                                                    className={
                                                        classes.checkboxWrapper
                                                    }
                                                >
                                                    <input
                                                        className={
                                                            classes.checkbox
                                                        }
                                                        type="checkbox"
                                                        value="fishing"
                                                        checked={filters.activities.includes(
                                                            'fishing'
                                                        )}
                                                        onChange={(e) => {
                                                            const value =
                                                                e.target.value
                                                            setFilters(
                                                                (
                                                                    prevFilters
                                                                ) => ({
                                                                    ...prevFilters,
                                                                    activities:
                                                                        prevFilters.activities.includes(
                                                                            value
                                                                        )
                                                                            ? prevFilters.activities.filter(
                                                                                  (
                                                                                      item
                                                                                  ) =>
                                                                                      item !==
                                                                                      value
                                                                              )
                                                                            : [
                                                                                  ...prevFilters.activities,
                                                                                  value,
                                                                              ],
                                                                })
                                                            )
                                                        }}
                                                    />
                                                    <label htmlFor="fishing">
                                                        Fishing
                                                    </label>
                                                </div>
                                                <div
                                                    className={
                                                        classes.checkboxWrapper
                                                    }
                                                >
                                                    <input
                                                        className={
                                                            classes.checkbox
                                                        }
                                                        type="checkbox"
                                                        value="cycling"
                                                        checked={filters.activities.includes(
                                                            'cycling'
                                                        )}
                                                        onChange={(e) => {
                                                            const value =
                                                                e.target.value
                                                            setFilters(
                                                                (
                                                                    prevFilters
                                                                ) => ({
                                                                    ...prevFilters,
                                                                    activities:
                                                                        prevFilters.activities.includes(
                                                                            value
                                                                        )
                                                                            ? prevFilters.activities.filter(
                                                                                  (
                                                                                      item
                                                                                  ) =>
                                                                                      item !==
                                                                                      value
                                                                              )
                                                                            : [
                                                                                  ...prevFilters.activities,
                                                                                  value,
                                                                              ],
                                                                })
                                                            )
                                                        }}
                                                    />
                                                    <label htmlFor="cycling">
                                                        Cycling
                                                    </label>
                                                </div>
                                            </label>
                                            <label
                                                className={classes.labelWrapper}
                                            >
                                                Vibe:
                                                <div
                                                    className={
                                                        classes.checkboxWrapper
                                                    }
                                                >
                                                    <input
                                                        className={
                                                            classes.checkbox
                                                        }
                                                        type="checkbox"
                                                        value="lively"
                                                        checked={filters.vibe.includes(
                                                            'lively'
                                                        )}
                                                        onChange={(e) => {
                                                            const value =
                                                                e.target.value
                                                            setFilters(
                                                                (
                                                                    prevFilters
                                                                ) => ({
                                                                    ...prevFilters,
                                                                    vibe: prevFilters.vibe.includes(
                                                                        value
                                                                    )
                                                                        ? prevFilters.vibe.filter(
                                                                              (
                                                                                  item
                                                                              ) =>
                                                                                  item !==
                                                                                  value
                                                                          )
                                                                        : [
                                                                              ...prevFilters.vibe,
                                                                              value,
                                                                          ],
                                                                })
                                                            )
                                                        }}
                                                    />
                                                    <label htmlFor="lively">
                                                        Lively
                                                    </label>
                                                </div>
                                                <div
                                                    className={
                                                        classes.checkboxWrapper
                                                    }
                                                >
                                                    <input
                                                        className={
                                                            classes.checkbox
                                                        }
                                                        type="checkbox"
                                                        value="quiet"
                                                        checked={filters.vibe.includes(
                                                            'quiet'
                                                        )}
                                                        onChange={(e) => {
                                                            const value =
                                                                e.target.value
                                                            setFilters(
                                                                (
                                                                    prevFilters
                                                                ) => ({
                                                                    ...prevFilters,
                                                                    vibe: prevFilters.vibe.includes(
                                                                        value
                                                                    )
                                                                        ? prevFilters.vibe.filter(
                                                                              (
                                                                                  item
                                                                              ) =>
                                                                                  item !==
                                                                                  value
                                                                          )
                                                                        : [
                                                                              ...prevFilters.vibe,
                                                                              value,
                                                                          ],
                                                                })
                                                            )
                                                        }}
                                                    />
                                                    <label htmlFor="quiet">
                                                        Quiet
                                                    </label>
                                                </div>
                                                <div
                                                    className={
                                                        classes.checkboxWrapper
                                                    }
                                                >
                                                    <input
                                                        className={
                                                            classes.checkbox
                                                        }
                                                        type="checkbox"
                                                        value="relaxing"
                                                        checked={filters.vibe.includes(
                                                            'relaxing'
                                                        )}
                                                        onChange={(e) => {
                                                            const value =
                                                                e.target.value
                                                            setFilters(
                                                                (
                                                                    prevFilters
                                                                ) => ({
                                                                    ...prevFilters,
                                                                    vibe: prevFilters.vibe.includes(
                                                                        value
                                                                    )
                                                                        ? prevFilters.vibe.filter(
                                                                              (
                                                                                  item
                                                                              ) =>
                                                                                  item !==
                                                                                  value
                                                                          )
                                                                        : [
                                                                              ...prevFilters.vibe,
                                                                              value,
                                                                          ],
                                                                })
                                                            )
                                                        }}
                                                    />
                                                    <label htmlFor="relaxing">
                                                        Relaxing
                                                    </label>
                                                </div>
                                                <div
                                                    className={
                                                        classes.checkboxWrapper
                                                    }
                                                >
                                                    <input
                                                        className={
                                                            classes.checkbox
                                                        }
                                                        type="checkbox"
                                                        value="rustic"
                                                        checked={filters.vibe.includes(
                                                            'rustic'
                                                        )}
                                                        onChange={(e) => {
                                                            const value =
                                                                e.target.value
                                                            setFilters(
                                                                (
                                                                    prevFilters
                                                                ) => ({
                                                                    ...prevFilters,
                                                                    vibe: prevFilters.vibe.includes(
                                                                        value
                                                                    )
                                                                        ? prevFilters.vibe.filter(
                                                                              (
                                                                                  item
                                                                              ) =>
                                                                                  item !==
                                                                                  value
                                                                          )
                                                                        : [
                                                                              ...prevFilters.vibe,
                                                                              value,
                                                                          ],
                                                                })
                                                            )
                                                        }}
                                                    />
                                                    <label htmlFor="rustic">
                                                        Rustic
                                                    </label>
                                                </div>
                                                <div
                                                    className={
                                                        classes.checkboxWrapper
                                                    }
                                                >
                                                    <input
                                                        className={
                                                            classes.checkbox
                                                        }
                                                        type="checkbox"
                                                        value="familyFun"
                                                        checked={filters.vibe.includes(
                                                            'familyFun'
                                                        )}
                                                        onChange={(e) => {
                                                            const value =
                                                                e.target.value
                                                            setFilters(
                                                                (
                                                                    prevFilters
                                                                ) => ({
                                                                    ...prevFilters,
                                                                    vibe: prevFilters.vibe.includes(
                                                                        value
                                                                    )
                                                                        ? prevFilters.vibe.filter(
                                                                              (
                                                                                  item
                                                                              ) =>
                                                                                  item !==
                                                                                  value
                                                                          )
                                                                        : [
                                                                              ...prevFilters.vibe,
                                                                              value,
                                                                          ],
                                                                })
                                                            )
                                                        }}
                                                    />
                                                    <label htmlFor="familyFun">
                                                        Family Fun
                                                    </label>
                                                </div>
                                            </label>
                                        </div>

                                        {/* <label>
                                            Sort by most sustainable
                                            <input
                                                
                                                checked={filters.sortOrder === 'sustainability'}
                                                onChange={handleSortOrderChange}
                                            />
                                        </label> */}
                                        <button
                                            onClick={handleClearAll}
                                            className={classes.clearButton}
                                        >
                                            Clear All
                                        </button>
                                    </div>
                                    <div className={classes.fixed}>
                                        <button
                                            onClick={() => setIsOpen(false)}
                                            className={classes.filterButton}
                                        >
                                            View {filteredCampsites.length}{' '}
                                            campsites
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </DialogModule>
                </div>
                <div className={classes.campsiteContainer}>
                    {sortedCampsites.length > 0 ? (
                        // Show the filtered campsites
                        sortedCampsites.map((campsite) => (
                            <Campsite key={campsite.id} campsite={campsite} />
                        ))
                    ) : (
                        // Show message when no campsites match the filters
                        <p>No campsites match the selected filters.</p>
                    )}
                </div>
            </div>
        </div>
    )
}

export default CampsiteFilter
