import React, { useState, Fragment, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Dialog, Transition } from '@headlessui/react'
import { useStyle } from '../../hooks/classify'
import * as defaultClasses from './campsiteGallery.module.css'
import Slider from '../slider'
import SearchIcon from '../../images/icons/search'
import DialogModule from '../dialogModel/DialogModel'
import CloseIcon from '../../images/icons/Close'

const CampsiteGallery = ({ campsite, parentClasses }) => {
    const classes = useStyle(defaultClasses)
    const [isOpen, setIsOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [galleryImages, setGalleryImages] = useState([])

    const data = useStaticQuery(graphql`
        query {
            allFile(
                filter: {
                    relativeDirectory: { regex: "/campsites/.*gallery/" }
                }
            ) {
                edges {
                    node {
                        id
                        relativeDirectory
                        childImageSharp {
                            gatsbyImageData(width: 1280)
                        }
                    }
                }
            }
        }
    `)

    const folder = `campsites/${campsite.replace(/\s/g, '')}/gallery`

    useEffect(() => {
        const filteredImages = data.allFile.edges
            .filter(
                ({ node }) =>
                    node.relativeDirectory &&
                    node.relativeDirectory.includes(folder)
            )
            .map(({ node }) => ({
                id: node.id,
                image: getImage(node.childImageSharp),
            }))

        setGalleryImages(filteredImages)
        setIsLoading(false)
    }, [data.allFile.edges, folder])

    return (
        <>
            <button
                className={classes.button}
                onClick={() => setIsOpen(true)}
                title="See gallery"
            >
                <SearchIcon className={classes.buttonIcon} />
            </button>
            <DialogModule isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="flex min-h-full items-center justify-center p-ms2 md_p-ms7 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="w-full max-w-screen-xl transform text-left align-middle shadow-xl transition-all">
                            <CloseIcon className={classes.closeButton} />
                            {isLoading ? (
                                <div>Loading...</div>
                            ) : (
                                <Slider
                                    effect="fade"
                                    loop={true}
                                    navigation={true}
                                    pagination={true}
                                    autoplay={7000}
                                    spaceBetween={30}
                                    autoHeight={true}
                                >
                                    {galleryImages.map(
                                        ({ id, image }, index) => (
                                            <GatsbyImage
                                                key={id}
                                                image={image}
                                                alt={`Image ${index}`}
                                                className={classes.image}
                                            />
                                        )
                                    )}
                                </Slider>
                            )}
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </DialogModule>
        </>
    )
}

export default CampsiteGallery
