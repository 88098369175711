import React, { useContext, useEffect, useState } from 'react'
import { FavouritesContext } from '../../providers/favouritesProvider'
import { useStyle } from '../../hooks/classify'
import { getImage } from 'gatsby-plugin-image'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as defaultClasses from './campsite.module.css'
import { useLocation } from '@reach/router'
import HeartIcon from '../../images/icons/heart'
import CampsiteGallery from '../campsiteGallery'

const Campsite = ({ campsite, parentClasses }) => {
    const classes = useStyle(defaultClasses, parentClasses)
    const { perfectFor, activities, vibe, pets, campfire, local, nearestTown } =
        campsite?.attributes

    function formatString(string) {
        return string
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase())
    }

    const { favourites, addToFavourites, removeFromFavourites } =
        useContext(FavouritesContext)
    const [isFavourite, setIsFavourite] = useState(false)

    useEffect(() => {
        setIsFavourite(favourites.includes(campsite?.id))
    }, [favourites, campsite?.id])

    const handleToggleFavourite = () => {
        if (isFavourite) {
            removeFromFavourites(campsite?.id)
        } else {
            addToFavourites(campsite?.id)
        }
    }

    useEffect(() => {
        setIsFavourite(favourites.includes(campsite?.id))
    }, [favourites, campsite?.id])

    const location = useLocation()
    const hasQueryString = location.search !== ''

    return (
        <div className={'campsite' + campsite?.id + ' ' + classes.container}>
            <div className={classes.imageWrapper}>
                <div className={classes.image}>
                    <GatsbyImage
                        image={getImage(
                            campsite?.image?.childImageSharp?.gatsbyImageData
                        )}
                        alt={campsite?.title}
                        className={classes.gatsbyImage}
                    />

                    <CampsiteGallery campsite={campsite?.title} />
                </div>
            </div>
            <div className={classes.detailsWrapper}>
                <div className={classes.titleWrapper}>
                    <h3 className={'hFour ' + classes.title}>
                        {campsite?.title}
                    </h3>
                    {!hasQueryString && (
                        <button
                            onClick={handleToggleFavourite}
                            title="Add to favourites"
                        >
                            {isFavourite ? (
                                <HeartIcon
                                    className={classes.like + ' fill-primary'}
                                />
                            ) : (
                                <HeartIcon
                                    className={classes.like + ' fill-stone-700'}
                                />
                            )}
                        </button>
                    )}
                </div>

                <p className={classes.caption}>{campsite?.caption}</p>
                <div
                    className={classes.description}
                    dangerouslySetInnerHTML={{ __html: campsite?.description }}
                />

                <div className={classes.location}>
                    <span>Location: {local}</span>
                    <span>Nearest Town: {nearestTown}</span>
                </div>
                <div className={classes.deets}>
                    <span>Pets: {pets ? 'Yes' : 'No'}</span>
                    <span>Campfire: {campfire ? 'Yes' : 'No'}</span>
                </div>

                <div className={classes.attributes}>
                    <div>
                        <span className={classes.strong}>Perfect for:</span>
                        <ul>
                            {perfectFor &&
                                Object.entries(perfectFor).map(
                                    ([key, value]) => {
                                        if (value) {
                                            return (
                                                <li key={key}>
                                                    {formatString(key)}
                                                </li>
                                            )
                                        }
                                        return null
                                    }
                                )}
                        </ul>
                    </div>
                    <div>
                        <span className={classes.strong}>Activities:</span>
                        <ul>
                            {Object.entries(activities).map(
                                ([activity, value]) =>
                                    value ? (
                                        <li key={activity}>
                                            {formatString(activity)}
                                        </li>
                                    ) : null
                            )}
                        </ul>
                    </div>
                    <div>
                        <span className={classes.strong}>Vibe:</span>
                        <ul>
                            {Object.entries(vibe).map(([vibe, value]) =>
                                value ? (
                                    <li key={vibe}>{formatString(vibe)}</li>
                                ) : null
                            )}
                        </ul>
                    </div>
                </div>

                <p className={classes.sustainability}>
                    Sustainability: {campsite?.sustainability}
                </p>
                <a
                    href={campsite?.link}
                    target="_blank"
                    rel="noreferrer"
                    className={'btnPrimary ' + classes.button}
                >
                    Visit website
                </a>
            </div>
        </div>
    )
}

export default Campsite
